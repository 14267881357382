import { getToken } from "./utlits";
const apiUrl = process.env.REACT_APP_API_URL;

const CREATE_CHECKOUT_SESSION = "stripe/CREATE_CHECKOUT_SESSION";

const createCheckoutSession = (session) => ({
    type: CREATE_CHECKOUT_SESSION,
    session
})

export const createCheckoutSessionThunk = (tip,restaurantId, cartItems, coupon, address) => async (dispatch) => {
    const token = getToken()

    const response = await fetch(`${apiUrl}/api/stripe/create-payment-intent-collect-delivery-fees`, {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            credentials: 'include',
            'Authorization': `Bearer ${token}`,
        },
        credentials: 'include',
        body: JSON.stringify({tip, restaurantId, cartItems, coupon, address})
    })
    if (response.ok) {
        const data = await response.json();
        dispatch(createCheckoutSession(data))
    }
}

const initialState = {};

const stripeReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_CHECKOUT_SESSION:
            return { 
                ...state, 
                data: action.session
            }
        default:
            return state;
    }
}

export default stripeReducer;
