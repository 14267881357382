import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { IMAGES } from "../constent/theme";


const Gallery = () => {
  // Extracting images from the restaurant data
  const images = [
    {
      img: 'https://comfortablefood.com/wp-content/uploads/2021/10/beef-fried-rice-featured-500x375.jpg',
    },
    {
      img: 'https://www.easyshmeezyrecipes.com/wp-content/uploads/2022/08/Beef-Lo-Mein-480x270.jpg',
    },
    {
      img: 'https://s3-media0.fl.yelpcdn.com/bphoto/y2j4A3NELXkgkh9L5UiwyA/300s.jpg',
    },
    {
      img: 'https://dynamic-media-cdn.tripadvisor.com/media/photo-o/0a/a4/8d/2c/china-wok.jpg?w=1200&h=-1&s=1',
    },
    {
      img: 'https://s3-media0.fl.yelpcdn.com/bphoto/oFn3_TM6-x78it75HPsFNQ/348s.jpg',
    },
    {
      img: 'https://s3-media0.fl.yelpcdn.com/bphoto/-sA2NFSRrlvX4wiO6LeFIg/1000s.jpg',
    },
    {
      img: 'https://dynamic-media-cdn.tripadvisor.com/media/photo-o/09/5e/1a/92/fried-pork-dumplings.jpg?w=600&h=400&s=1',
    },
    {
      img: 'https://s3-media0.fl.yelpcdn.com/bphoto/4b_xdhpJ9KbTZBaJ3pw8Iw/300s.jpg',
    },
    
    
  ]

  return (
    <div className="container">
      <Swiper
        className="swiper team-swiper swiper-visible swiper-btn-lr swiper-item-4"
        slidesPerView={4}
        spaceBetween={30}
        loop={true}
        speed={1200}
        modules={[Autoplay, Navigation]}
        navigation={{
          prevEl: ".team-button-prev",
          nextEl: ".team-button-next",
        }}
        autoplay={{
          delay: 1500,
        }}
        breakpoints={{
          1200: { slidesPerView: 4 },
          991: { slidesPerView: 3 },
          575: { slidesPerView: 2 },
          240: { slidesPerView: 1 },
        }}
      >
        {images?.map((img, ind) => (
          <SwiperSlide className="swiper-slide" key={ind}>
            <div className="dz-team style-1 wow fadeInUp">
              <div className="dz-media">
              <img
  src={img.img} // Access the correct property of the image object
  alt={`Image ${ind + 1}`}
  className="gallery-image img-fluid"
/>

              </div>
            </div>
          </SwiperSlide>
        ))}
        <div className="pagination mt-xl-0 m-t40">
          <div className="team-button-prev btn-prev-long">
            <i className="fa-solid fa-arrow-left"></i>
          </div>
          <div className="team-button-next btn-next-long">
            <i className="fa-solid fa-arrow-right"></i>
          </div>
        </div>
      </Swiper>
    </div>
  );
};

export default Gallery;
