import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import { useState } from "react";

const Home2OurMenu = ({ prev, next }) => {
  const [active, setActive] = useState();

  // Hard-coded restaurant data
  const restaurant = {
    Categories: [
      {
        id: 1,
        name: "Fried Rice",
        Items: [
          {
            id: 1,
            name: "Egg Fried Rice",
            price: 8.15,
          },
          {
            id: 2,
            name: "Roast Pork Or Chicken Fried Rice",
            price: 8.15,
          },
          {
            id: 3,
            name: "Beef Or Shrimp Fried Rice",
            price: 8.15,
          },
          {
            id: 4,
            name: "Seafood Fried Rice",
            price: 16.25,
          },
          // Add more items here...
        ],
      },
      {
        id: 2,
        name: "Specials",
        Items: [
          {
            id: 5,
            name: "Fried Chicken Wings(6pcs)",
            price: 10.95,
          },
          {
            id: 6,
            name: "Fried Baby Shrimp",
            price: 10.95,
          },
          {
            id: 7,
            name: "Fried Chicken Nuggets(12)",
            price: 129.95,
          },
          // Add more items here...
        ],
      },
      // Add more categories here...
    ],
  };

  return (
    <div className="container">
      <Swiper
        className="swiper menu-swiper swiper-visible swiper-item-4"
        slidesPerView={4}
        spaceBetween={30}
        speed={1500}
        loop={true}
        modules={[Autoplay, Navigation]}
        autoplay={{ delay: 1500 }}
        navigation={{
          prevEl: `.${prev}`,
          nextEl: `.${next}`,
        }}
        breakpoints={{
          1200: { slidesPerView: 4 },
          991: { slidesPerView: 3 },
          575: { slidesPerView: 2 },
          240: { slidesPerView: 1 },
        }}
      >
        {restaurant?.Categories?.map((category) =>
          category?.Items?.map((item) => (
            <SwiperSlide className="swiper-slide" key={item.id}>
              <div
                className={`dz-img-box style-4 box-hover ${
                  active === item.id ? "active" : ""
                }`}
                onMouseEnter={() => {
                  setActive(item.id);
                }}
              >
                <div className="menu-detail">
                  <div className="dz-content">
                    <h6 className="title">
                      <Link to="/online-order">{item?.name}</Link>
                    </h6>
                  </div>
                </div>
                <div className="menu-footer">
                  <span>Regular Price</span>
                  <span className="price">${item?.price}</span>
                </div>
                <Link className="detail-btn" to="/online-order">
                  <i className="fa-solid fa-plus"></i>
                </Link>
              </div>
            </SwiperSlide>
          ))
        )}
      </Swiper>
    </div>
  );
};

export default Home2OurMenu;
