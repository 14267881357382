/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import logo from "./logo.png"
import logo2 from "../assets/images/logo2.png";


import duck1 from "../assets/images/client/duck1.png"
import duck2 from "../assets/images/client/duck2.png"
import duck3 from "../assets/images/client/duck3.png"
import duck4 from "../assets/images/client/duck4.png"
import duck5 from "../assets/images/client/duck5.png"
import duck6 from "../assets/images/client/duck6.png"
import duck7 from "../assets/images/client/duck7.png"
import duck8 from "../assets/images/client/duck8.png"
import duck9 from "../assets/images/client/duck9.png"
import duck10 from "../assets/images/client/duck10.png"
import duck11 from "../assets/images/client/duck11.png"
import duck12 from "../assets/images/client/duck12.png"
import background from "../assets/images/bk.png"
import background1 from '../assets/images/background/chefhouseback.jpg'
import sushi1 from '../assets/images/background/sushi1.jpg'
import sushi2 from '../assets/images/background/sushi2.jpg'
import sushi3 from '../assets/images/background/sushi3.jpg'
import sushi4 from '../assets/images/background/sushi4.jpg'


export const IMAGES = {
  duck1: duck1,
  duck2: duck2,
  duck3: duck3,
  duck4: duck4,
  duck5: duck5,
  duck6: duck6,
  duck7: duck7,
  duck8 : duck8,
  duck9 : duck9,
  duck10 : duck10,
  duck11 : duck11,
  logo: logo,
  logo2: logo2,
    duck12: duck12,
    background: background,
    background1: background1,
    sushi1: sushi1,
    sushi2: sushi2,
    sushi3: sushi3,
    suhi4: sushi4
};
