import { sassTrue } from "sass";
import Swal from "sweetalert2";

export const isStoreOpen = () => {
  // Get the current date and time in the Central Time Zone
  const currentDate = new Date().toLocaleString("en-US", {
      timeZone: "America/Chicago", // Set the time zone to Central Time Zone
  });

  // Extract the day of the week (0: Sunday, 1: Monday, ..., 6: Saturday)
  const currentDayOfWeek = new Date(currentDate).getDay();

  // Extract the current time in HH:mm format
  const currentTime = new Date(currentDate).toLocaleTimeString("en-US", {
      hour12: false, // Use 24-hour format
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "America/Chicago", // Set the time zone to Central Time Zone
  });

  // Define the store's opening hours
  const openingHours = [
      { day: 0, start: "11:00", end: "21:00" }, // Sunday
      { day: 1, start: "10:30", end: "21:00" }, // Monday
      { day: 2, start: "10:30", end: "21:00" }, // Tuesday
      { day: 3, start: "10:30", end: "21:00" }, // Wednesday
      { day: 4, start: "12:00", end: "20:45" }, // Thursday
      { day: 5, start: "10:30", end: "21:30" }, // Friday
      { day: 6, start: "10:30", end: "21:30" }, // Saturday
  ];

  const openingHour = openingHours.filter((hour) => hour.day === currentDayOfWeek);
  // If openingHour is undefined, it means the store is closed today
  if (openingHour.length === 0) {
      return false;
  }

  // Check if the current time is within any of the store's opening hours
  const isOpen = openingHour.some((hour) => {
      const startTime = new Date(`01/01/2022 ${hour.start}`);
      const endTime = new Date(`01/01/2022 ${hour.end}`);
      const currentTimeFormatted = new Date(`01/01/2022 ${currentTime}`);
      return currentTimeFormatted >= startTime && currentTimeFormatted <= endTime;
  });

  return isOpen;
};

export const checkIfLunchTime = () => {
  // Get the current date and time in the Central Time Zone
  const currentDate = new Date().toLocaleString("en-US", {
      timeZone: "America/Chicago", // Set the time zone to Central Time Zone
  });

  // Extract the current time in HH:mm format
  const currentTime = new Date(currentDate).toLocaleTimeString("en-US", {
      hour12: false, // Use 24-hour format
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "America/Chicago", // Set the time zone to Central Time Zone
  });

  // Extract the current day
  const currentDay = new Date(currentDate).getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday

  // Define the store's lunch hours (Mon - Fri)
  const lunchHours = { start: "10:30", end: "15:00" };

  // Check if it's the weekend (Saturday or Sunday)
  if (currentDay === 0 || currentDay === 6) {
      return false; // No lunch hours on weekends
  }

  // Extract hours and minutes from current time
  const [currentHour, currentMinute] = currentTime.split(":").map(Number);

  // Extract hours and minutes from lunch hours
  const [startHour, startMinute] = lunchHours.start.split(":").map(Number);
  const [endHour, endMinute] = lunchHours.end.split(":").map(Number);

  // Check if current time is within lunch hours
  const isLunchTime =
      (currentHour > startHour || (currentHour === startHour && currentMinute >= startMinute)) &&
      (currentHour < endHour || (currentHour === endHour && currentMinute <= endMinute));

  return isLunchTime;
};


export const showTimeError = () => {
    Swal.fire({
      icon: 'error',
      title: 'Please come back later.',
      text: 'Hours:11:30-9:15PM, Sun:12:00-9:15PM Eastern Time. Closed on Wednesdays.',

    })
  }
